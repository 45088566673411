<template>
  <div class="product-page">
    <v-row no-gutters class="justify-space-between">
      <v-col class="page-header"
        ><span class="page-title">{{ $t('Updates') }}</span></v-col
      >
      <v-btn @click="$router.push({ name: 'website.bulkupdates.create' })" class="primary">Create an Update</v-btn>
      <!-- <v-btn class="primary">Create an Update</v-btn> -->
    </v-row>
    <v-row class="mb-4">
      <v-card-subtitle
        >For stores with hundreds of products, updating product descriptions, prices and compare-at-prices manually will
        take much time and effort. Bulk update tool might be helpful for you.
        <!-- <span class="click-link primary--text">Learn more</span>. -->
      </v-card-subtitle>
      <!-- a -->
    </v-row>
    <!-- <v-col cols="12">
      <Resource :filter-data="filterData" :columns="columns" :updateRouterCallback="getUpdateRouterData" />
    </v-col> -->
    <div class="elevation-2">
      <v-row style="" class="mx-1">
        <v-col class="text-center black--text" sm="1"><h4>ID</h4></v-col>
        <v-col class="text-center black--text" sm="1"><h4>STATUS</h4> </v-col>
        <v-col class="text-left black--text" sm="2"><h4>FILTER</h4> </v-col>
        <v-col class="text-center black--text" sm="2"><h4>UPDATE FOR</h4> </v-col>
        <v-col class="text-left black--text" sm="3"><h4>ACTION</h4> </v-col>
        <v-col class="text-left black--text px-0" sm="1"><h4>NUMBER OF UPDATED PRODUCTS</h4> </v-col>
        <v-col class="text-left black--text" sm="2"><h4>CREATED AT</h4> </v-col>
      </v-row>
      <v-divider class="mx-3 mb-6"></v-divider>
      <v-row v-for="(item, ind) in document" :key="ind" class="mx-0">
        <v-col class="text-center" sm="1">{{ ind + 1 }}</v-col>
        <!-- Status -->
        <v-col class="text-center" sm="1" v-if="item.status == 0 || item.status == 1">
          <v-progress-circular indeterminate :value="20" size="18" width="2" color="primary"></v-progress-circular>
        </v-col>
        <v-col class="text-center" sm="1" v-if="item.status == 2">
          <v-icon color="primary">
            mdi-checkbox-marked-circle
          </v-icon>
        </v-col>
        <v-col class="text-center" sm="1" v-if="item.status == 4">
          <v-icon color="error">
            mdi-alert-circle
          </v-icon>
        </v-col>
        <!-- Filter -->
        <v-col v-if="item && item.filter && item.filter.rules" class="text-left" sm="2">
          <div v-for="(item1, ind1) in item.filter.rules" :key="ind1">
            {{ item1.column != '' && item1.column && item1 ? changeColumnName(item1.column) : '' }}
            {{ item1.relation != '' && item1.relation && item1 ? changeColumnRelation(item1.relation) : '' }}
            {{ item1.condition }}
          </div>
        </v-col>
        <!-- Update for -->
        <v-col v-if="item && item.variantFilter && item.variantFilter.rules" class="text-center" sm="2">
          <div v-for="(item2, ind2) in item.variantFilter.rules" :key="ind2">
            {{ item2.column != '' ? changeColumnVariantName(item2.column) : '' }}
            {{ item2.relation != '' ? changeColumnVariantRelation(item2.relation) : '' }} {{ item2.condition }}
          </div>
        </v-col>
        <v-col v-if="!item.variantFilter" class="text-center" sm="2">
          All Variants
        </v-col>
        <!-- Action -->
        <v-col v-if="item && item.actions" class="text-left" sm="3">
          <div v-for="(item3, ind3) in item.actions" style="word-wrap: break-word; overflow: hidden;" :key="ind3">
            <!-- tên action -->
            {{ item3.command ? changeActionName(item3.command) : '' }}
            {{
              item3.extra && item3.extra.add_custom_option && item3.extra.add_custom_option.list
                ? changeActionTitle(item3.extra.add_custom_option.list)
                : ''
            }}
            <!-- Hiện điều kiện filter -->
            {{ item3.extra && item3.extra.extraType ? changeConditionTitle(item3.extra.extraType) : '' }}
            <!-- Hiện value của price -->
            <span v-html="changeActionValue(item3)"></span>
            <!-- <span v-else>{{ item3.value[0] }}</span> -->
            <!-- {{ utilities.stringLimit(changeActionValue(item3), 60) }} -->
            <!-- Hiện Prefix của price -->
            {{ displayPrefix(item3) }}
            <!-- {{
            item3.extra.add_custom_option && item3.extra.add_custom_option.position
              ? item3.extra.add_custom_option.position
              : ''
          }} -->
            {{ item3.command && item3.command.includes('add_custom_options') ? 'existing custom options' : '' }}
          </div>
        </v-col>
        <!-- Number of update product -->
        <v-col class="text-left" sm="1">{{ item.totalProduct >= 0 ? item.totalProduct : '-' }}</v-col>
        <!-- <v-col class="text-center" sm="1">{{ counttt ? count : '-' }}</v-col> -->
        <v-col class="text-left" sm="2">{{
          moment(item.createdDate)
            .tz(pagePreference.standardTimezone || 'America/Los_Angeles')
            .format('MMM D, YYYY hh:mm A')
        }}</v-col>
        <!-- <div>{{ pagePreference.standardTimezone }}</div> -->
        <v-col sm="12"><v-divider></v-divider></v-col>
      </v-row>
      <v-col cols="12" v-if="!isLoading && document && document.length === 0" class="d-flex justify-center">
        <Empty />
      </v-col>
      <v-col cols="12" v-if="!isLoading && document && document.length === 0" class="d-flex justify-center">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="d-flex justify-space-between">
        <limit-page :callback="setLimit" :page.sync="page" />
        <pagination :length="1000000000000" :visible="0" :page.sync="page"></pagination>
      </v-col>
    </div>
  </div>
</template>

<script>
import Resource from '@/components/Resources';
import utilities from '@/helpers/utilities';
import resourceListMixin from '@/mixins/resource-list';
import moment from 'moment';
import { mapState } from 'vuex';
import { FETCH_PAGE_PREFERRENCE } from '@/store/actions.type';
import { eventBus } from '../../../main.js';

// import { mapState } from 'vuex';
export default {
  // components: { Resource },
  mixins: [resourceListMixin],
  data() {
    return {
      utilities,
      moment,
      resourceType: 'bulkupdates',
      columns: {
        // Selectable: {
        //   blankLabel: true,
        //   kind: 'selectable',
        //   width: 1,
        // },
        ID: {
          field: 'id',
          kind: 'view',
        },
        Status: {
          field: 'status',
          kind: 'view',
        },
        Filter: {
          field: 'filter',
          kind: 'text',
        },
        'Update for': {
          field: 'updateFor',
          kind: 'text',
        },

        Action: {
          field: 'action',
          kind: 'text',
        },
        'Number of updated products': {
          kind: 'numberOfUpdated',
          field: 'text',
          // blankLabel: true,
        },
        'Created at': {
          field: 'createdAt',
          kind: 'text',
        },
      },
      // itemsVariant2: [
      //   {
      //     title: 'contains',
      //     value: 'contain',
      //     customDisabledFilter: false,
      //   },
      //   {
      //     title: 'does not contains',
      //     value: 'notContain',
      //     customDisabledFilter: false,
      //   },
      //   {
      //     title: 'starts with',
      //     value: 'startWith',
      //     customDisabledFilter: false,
      //   },
      //   {
      //     title: 'ends with',
      //     value: 'endWith',
      //     customDisabledFilter: false,
      //   },
      //   {
      //     title: 'is equal to',
      //     value: 'equal',
      //     customDisabledVariant: false,
      //   },
      //   {
      //     title: 'is not equal to',
      //     value: 'notEqual',
      //     customDisabledVariant: false,
      //   },
      //   {
      //     title: 'is greater than',
      //     value: 'greaterThan',
      //     customDisabledVariant: false,
      //   },
      //   {
      //     title: 'is less than',
      //     value: 'lessThan',
      //     customDisabledVariant: false,
      //   },
      // ],
      // itemsVariant1: [
      //   {
      //     title: 'Variant title',
      //     value: 'variant.title',
      //     compare: ['equal', 'notEqual', 'startWith', 'endWith', 'contain', 'notContain'],
      //   },
      //   {
      //     title: 'Variant price',
      //     value: 'variant.price',
      //     compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
      //   },
      //   {
      //     title: 'Variant compare-at-price',
      //     value: 'variant.compareAtPrice',
      //     compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
      //   },
      //   {
      //     title: 'Variant weight',
      //     value: 'variant.weight',
      //     compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
      //   },
      // ],
      // itemsFilter2: [
      //   {
      //     title: 'contains',
      //     value: 'contain',
      //     customDisabledFilter: false,
      //   },
      //   {
      //     title: `doesn't contains`,
      //     value: 'notContain',
      //     customDisabledFilter: false,
      //   },
      //   {
      //     title: 'is equal to',
      //     value: 'equal',
      //     customDisabledFilter: false,
      //   },
      //   {
      //     title: 'is not equal to',
      //     value: 'notEqual',
      //     customDisabledFilter: false,
      //   },
      //   {
      //     title: 'is greater than',
      //     value: 'greaterThan',
      //     customDisabledFilter: false,
      //   },
      //   {
      //     title: 'is less than',
      //     value: 'lessThan',
      //     customDisabledFilter: false,
      //   },
      //   {
      //     title: 'starts with',
      //     value: 'startWith',
      //     customDisabledFilter: false,
      //   },
      //   {
      //     title: 'ends with',
      //     value: 'endWith',
      //     customDisabledFilter: false,
      //   },
      // ],
      // itemsFilter1: [
      //   {
      //     title: 'Product title',
      //     value: 'title',
      //     compare: ['equal', 'notEqual', 'startWith', 'endWith', 'contain', 'notContain'],
      //   },
      //   {
      //     title: 'Product type',
      //     value: 'productType',
      //     compare: ['equal', 'notEqual', 'startWith', 'endWith', 'contain', 'notContain'],
      //   },
      //   {
      //     title: 'Product tag',
      //     value: 'tags',
      //     compare: ['equal', 'contain', 'notContain'],
      //   },
      //   {
      //     title: 'Product vendor',
      //     value: 'vendor',
      //     compare: ['equal', 'notEqual', 'startWith', 'endWith', 'contain', 'notContain'],
      //   },
      //   {
      //     title: 'Product price',
      //     value: 'variant.price',
      //     compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
      //   },
      //   {
      //     title: 'Compare at price',
      //     value: 'variant.compareAtPrice',
      //     compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
      //   },
      //   {
      //     title: 'Weight',
      //     value: 'variant.weight',
      //     compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
      //   },
      //   {
      //     title: 'Inventory Stock',
      //     value: 'inventoryQuantity',
      //     compare: ['equal', 'notEqual', 'greaterThan', 'lessThan'],
      //   },
      //   {
      //     title: 'Variant title',
      //     value: 'variant.title',
      //     compare: ['equal', 'notEqual', 'startWith', 'endWith', 'contain', 'notContain'],
      //   },
      //   {
      //     title: 'Collection',
      //     value: 'collections',
      //     compare: ['equal', 'notEqual'],
      //   },
      // ],
      counttt: '',
    };
  },
  async beforeMount() {
    if (!this.pagePreference.standardTimezone) {
      this.getTimezoneDone = false;
      await this.$store.dispatch(FETCH_PAGE_PREFERRENCE);
      this.getTimezoneDone = true;
    }
  },
  computed: {
    ...mapState({
      pagePreference: state => state.pagePreference.preference,
    }),
  },
  mounted() {
    eventBus.$on('getCount', ae => {
      this.counttt = ae;
      console.log(this.counttt);
    });
  },
};
</script>

<style lang="scss">
.click-link:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
